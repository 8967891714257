/* eslint-disable no-new */
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { fetchJSON } from '@dev/js/Utils';
import { MAP_API_KEY } from '@dev/js/constants';
import mapStyle from '../mapStyle';

export default class GoogleMap {
  constructor(config) {
    this.config = {
      ...{
        data: {},
        map: document.querySelector('.map'),
        lang: 'ru_RU',
        onload: 'initGoogleMap',
        apikey: MAP_API_KEY,
        mapScript: `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&callback=initGoogleMap`,
        icon: 'map_marker.svg',
        center: { lat: 65.584647, lng: 101.445215 },
        pageId: 1,
        zoom: 3,
        iconSize: 60,
      },
      ...config,
    };
    this.map = {};
    this.gmaps = {};
    this.addresses = [];
    this.icon = {
      url: `/assets/img/svg/${this.config.icon}`,
    };

    this.lazyLoad();
  }

  static getPopupChunk({ title, subtitle, desc }) {
    const chunk = `
        <div id="content" class="second">
          <div class="map_popup_title">${title}</div>
          <div class="map_popup_subtitle">${subtitle}</div>
          <div class="map_popup_desc">${desc}</div>
        </div>
        `;
    return chunk;
  }

  lazyLoad() {
    const listener = () => {
      const mapCoord = this.config.map.getBoundingClientRect().top + window.pageYOffset;
      const scrolled = window.pageYOffset + window.outerHeight;

      if (scrolled >= mapCoord) {
        document.removeEventListener('scroll', listener);
        this.initMapScript();
      }
    };

    document.addEventListener('scroll', listener);
  }

  checkMap() {
    return !!this.config.map;
  }

  createMap() {
    this.gmaps = window.google.maps;

    this.map = new this.gmaps.Map(this.config.map, {
      center: this.config.center,
      zoom: this.config.zoom,
      styles: mapStyle,
      disableDefaultUI: true,
    });

    this.initZoomControls();
    this.initMarkers();
    this.initClusters();
    this.markersEvent();
  }

  initZoomControls() {
    const controlDiv = document.createElement('div');
    const controlWrapper = document.createElement('div');
    // Set CSS for the control wrapper
    controlWrapper.classList.add('map_zoom_wrapper', 'flex', 'fdc');
    controlDiv.appendChild(controlWrapper);

    // Set CSS for the zoomIn
    const zoomInButton = document.createElement('div');
    zoomInButton.classList.add('map_zoom_control', 'button_circle', 'button--plus', 'button--white');
    controlWrapper.appendChild(zoomInButton);

    // Set CSS for the zoomOut
    const zoomOutButton = document.createElement('div');
    zoomOutButton.classList.add('map_zoom_control', 'button_circle', 'button--minus', 'button--white');
    controlWrapper.appendChild(zoomOutButton);

    // Setup the click event listener - zoomIn
    this.gmaps.event.addDomListener(zoomInButton, 'click', () => {
      this.map.setZoom(this.map.getZoom() + 1);
    });

    // Setup the click event listener - zoomOut
    this.gmaps.event.addDomListener(zoomOutButton, 'click', () => {
      this.map.setZoom(this.map.getZoom() - 1);
    });

    controlDiv.index = 1;
    this.map.controls[this.gmaps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
  }

  initMarkers() {
    this.icon.scaledSize = new this.gmaps.Size(this.config.iconSize, this.config.iconSize);

    this.markers = this.addresses.map((item) => new this.gmaps.Marker({
      position: { lat: +item.lat, lng: +item.lng },
      icon: this.icon,
      map: this.map,
      title: item.title,
      subtitle: item.subtitle,
      desc: item.desc,
    }));
  }

  initClusters() {
    const clusterStyles = [
      {
        textColor: 'white',
        url: 'assets/img/svg/map_marker_clear.svg',
        height: this.config.iconSize,
        width: this.config.iconSize,
      },
      {
        textColor: 'white',
        url: 'assets/img/svg/map_marker_clear.svg',
        height: this.config.iconSize,
        width: this.config.iconSize,
      },
      {
        textColor: 'white',
        url: 'assets/img/svg/map_marker_clear.svg',
        height: this.config.iconSize,
        width: this.config.iconSize,
      },
    ];

    const mcOptions = {
      gridSize: this.config.iconSize,
      styles: clusterStyles,
      maxZoom: 15,
    };

    const clusters = new MarkerClusterer(this.map, this.markers, mcOptions);
    clusters.setClusterClass('map_cluster');
  }

  markersEvent() {
    this.markers.forEach((mark) => {
      mark.addListener('click', () => {
        const popup = new this.gmaps.InfoWindow({ content: GoogleMap.getPopupChunk(mark) });
        popup.open(this.map, mark);
      });
    });
  }

  async fetchData() {
    await fetchJSON(window.location.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ getMapJson: true, pageId: this.config.pageId }),
    }).then((data) => {
      if (data.success) {
        this.addresses = JSON.parse(data.data);
      }
    });
  }

  async initMapScript() {
    if (this.checkMap()) {
      await this.fetchData();
      this.loadMapAPI();
    }
  }

  loadMapAPI() {
    const script = document.createElement('script');
    script.src = this.config.mapScript;
    script.type = 'text/javascript';
    script.async = true;
    document.querySelector('body').appendChild(script);
  }
}

import { csrfToken } from '@dev/js/constants';

export const fetchJSON = (url, config) => {
  const { headers, ...otherConfig } = config;
  const modifierConfig = {
    cache: 'no-cache',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken,
      ...headers,
    },
    ...otherConfig,
  };

  return fetch(url, modifierConfig).then((response) => response.json());
};

export const delegate = (el, evt, sel, handler) => {
  el.addEventListener(evt, function (event) {
    let t = event.target;
    while (t && t !== this) {
      if (t.matches(sel)) {
        handler.call(t, event);
      }
      t = t.parentNode;
    }
  });
};

export const scrollTo = (el) => {
  const headerHeight = document.querySelector('.header').clientHeight;
  const block = document.querySelector(el.getAttribute('href'));
  const top = block.getBoundingClientRect().top + window.pageYOffset - headerHeight;

  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

export const invertChange = (bool) => {
  const invertElems = document.querySelectorAll('.invert');
  const mainBanner = document.querySelector('.banner_main');

  if (!mainBanner) {
    invertElems.forEach((item) => {
      item.classList.toggle('invert--active', bool);
    });
    return;
  }

  if ((!bool && window.pageYOffset < (mainBanner.offsetHeight / 2))) {
    return;
  }

  invertElems.forEach((item) => {
    item.classList.toggle('invert--active', bool);
  });
};

const scrollСondition = (el, bool) => {
  const header = document.querySelector('.header');
  const headerMenu = document.querySelector('.header_menu');
  const lines = document.querySelectorAll('.lines_item');
  const condition = window.pageYOffset >= el.offsetHeight / 2;
  const popupMenu = document.querySelector('.popup_menu');

  lines.forEach((line) => {
    if (line.classList.contains('lines_item--full')) {
      return;
    }

    line.classList.toggle('lines_item--crop', condition);
  });

  header.classList.toggle('header--scrolled', condition);
  headerMenu.classList.toggle('header_menu--scrolled', condition);
  popupMenu.classList.toggle('popup_menu--scrolled', condition);

  if (bool) {
    header.classList.toggle('bg--white', condition);
    invertChange(!condition);
  }
};

export const checkScroll = () => {
  const mainBanner = document.querySelector('.banner_main');
  const innerBanner = document.querySelector('.banner_inner');

  if (innerBanner) {
    scrollСondition(innerBanner);
    return;
  }

  if (mainBanner) {
    scrollСondition(mainBanner, true);
  }
};

const validateSize = (fileInput, size) => {
  let fileObj;

  if (typeof window.ActiveXObject === 'function') {
    fileObj = (new window.ActiveXObject('Scripting.FileSystemObject')).getFile(fileInput.value);
  } else {
    [fileObj] = fileInput.files;
  }

  const oSize = fileObj.size;

  if (oSize > size * 1024 * 1024) {
    return false;
  }

  return true;
};

export const checkFile = (input) => {
  const fileInput = input;
  const types = ['docx', 'doc', 'pdf'];
  const parent = fileInput.closest('.form_input');
  const fileType = fileInput.value.split('.').pop().toLowerCase();
  const fileName = fileInput.value.split(/(\\|\/)/g).pop().toLowerCase();
  const warningClass = 'form_input_file_warning';
  const warning = parent.querySelector(`.${warningClass}`);

  let message = '';
  let addClass = 'color--red';
  let removeClass = 'color--green';

  if (types.indexOf(fileType) === -1) {
    message = 'Недопустимый формат файла';
    fileInput.value = '';
  } else if (!validateSize(input, 10)) {
    message = 'Файл не должен привышать 10 мбайт';
  } else {
    message = fileName;
    addClass = 'color--green';
    removeClass = 'color--red';
  }

  warning.textContent = message;
  warning.classList.remove(removeClass);
  warning.classList.add(`${warningClass}--active`, addClass);
};

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= -300
      && rect.bottom <= (window.outerHeight || document.documentElement.outerHeight) + 300
  );
};

export const addAutoResize = (input) => {
  const element = input;
  element.style.boxSizing = 'border-box';
  const offset = element.offsetHeight - element.clientHeight;

  element.addEventListener('input', (e) => {
    const { target } = e;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight + offset}px`;
  });

  element.removeAttribute('data-autoresize');
};

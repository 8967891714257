import {
  Swiper, Navigation, Autoplay, Pagination,
} from 'swiper';

Swiper.use([Navigation, Autoplay, Pagination]);

if (document.querySelector('.persons_slider .swiper-container')) {
  // eslint-disable-next-line no-new
  new Swiper('.persons_slider .swiper-container', {
    loop: false,
    speed: 800,
    slidesPerView: 1,
    autoHeight: true,
    effect: 'fade',
    simulateTouch: false,
    fadeEffect: {
      crossFade: true,
    },
    watchSlidesProgress: true,
    slideActiveClass: 'persons_slide--active',
    pagination: {
      el: '.persons_slider .persons_slider_list',
      bulletClass: 'persons_slider_list_item',
      bulletActiveClass: 'persons_slider_list_item--active',
      clickable: true,
      renderBullet(index, className) {
        const image = this.slides[index].getAttribute('data-prew');
        return `<div class="${className}"><img class="persons_slider_list_preview" src="${image}" alt="${index}"></div>`;
      },
    },
    on: {
      activeIndexChange: ({ snapIndex }) => {
        const list = document.querySelector('.persons_slider_list');
        const activeElem = list.querySelectorAll('.persons_slider_list_item')[snapIndex];
        const yOffset = list.getBoundingClientRect().top;
        const top = activeElem.getBoundingClientRect().top - yOffset;
        const left = activeElem.offsetLeft;
        const config = { behavior: 'smooth' };

        if (document.body.clientWidth > 1024) {
          config.top = top;
        }

        if (document.body.clientWidth <= 1024) {
          config.left = left;
        }

        activeElem.classList.add('sliding');

        list.scrollTo(config);
      },
      setTranslate(swiper) {
        const wrapper = swiper.$wrapperEl[0];
        wrapper.style.transform = 'none';
        wrapper.style.transitionDuration = '0s';
      },
      slideChangeTransitionStart({ previousIndex, slides }) {
        const classNeighbour = 'persons_slide--neighbour';
        const prevSlide = slides[previousIndex];

        slides.forEach((item) => {
          if (item.classList.contains(classNeighbour)) {
            item.classList.remove(classNeighbour);
          }
        });

        prevSlide.classList.add(classNeighbour);
      },
    },
    navigation: {
      nextEl: '.persons_slider .button_circle--next',
      prevEl: '.persons_slider .button_circle--prev',
      disabledClass: 'button--disabled',
    },
    breakpoints: {
      1024: {
        autoHeight: false,
      },
    },
  });
}

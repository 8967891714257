/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
import '@modules/PersonSlider';
import GoogleMap from '@modules/GoogleMap';
import '@dev/scss/templates/index.scss';

const mapConfig = {
  map: document.querySelector('.map'),
};

if (document.body.clientWidth <= 1024) {
  mapConfig.iconSize = 40;
}

if (document.body.clientWidth <= 480) {
  mapConfig.zoom = 2;
}

const map = new GoogleMap(mapConfig);

window.initGoogleMap = () => {
  map.createMap();
};
